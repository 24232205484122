import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col } from 'react-bootstrap';
import Aos from 'aos';
import "aos/dist/aos.css";
import { useEffect } from 'react';
import './style.css';



const Howitswork = () => {
    useEffect(() => {
        Aos.init({});
      }, []);
    return (
        <div id='howitswork' className='howitswork'>
            <Container>
                <Row className='alignhowitswork'>
                    <Col md='6'>
                        <div className='letsstarted-seccard'>
                            {/* <div data-aos='slide-right' className='quoteimg'>
                                <img src="./icons/quoteup.png" alt="" />
                            </div> */}
                            <div className='titlesection'>
                                <h1 data-aos='fade-right'>How <br></br>WFMS Works</h1>
                                <p data-aos='fade-right'>This end to end secure cloud application provides differentiated login options for managers and workers and enables real time connection between employees and enterprises. With our higher performing and intuitive interface, the application helps managers assign, track and monitor work as and when required irrespective of the size and complexity of the task at hand. The employee receives notification of assigned task and from thereon the process of communication between manager and employee on the subject is recorded and tracked. WFMS is not only a job scheduling platform but also a communication channel where the employees and managers can share insights on the workflow.</p>
                            </div>
                        </div>
                    </Col>
                    <Col data-aos='fade-left' md='6'>
                        <div className='imageforlrtsget'>
                            <img src="./lap-main.png" alt="" />
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Howitswork