import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col } from 'react-bootstrap';
import Aos from 'aos';
import "aos/dist/aos.css";
import { useEffect } from 'react';
import './style.css';



const Bestemployeesection = () => {
  useEffect(() => {
    Aos.init({});
  }, []);
  return (
    <div id='bestemployee' className='bestemployee'>
      <Container>
        <Row>
          <Col className='bestemployee-heading' md='12'>
            <div className='employeeheading'>
              <span className='heademp1'>Best WFMS For</span><br></br>
              <span className='heademp2'>Employee Management</span>
            </div>
          </Col>
        </Row>

        <Row className='mt-5'>

          <Col data-aos='fade-right' md='6'>
            <div className='card1main1 d-flex'>
              <Col md='3'>
                <div className='logocard1'>
                  <img src="./icons/icon1.png" alt="" />
                </div>
              </Col>
              <Col md='9'>
                <div className='empcardhead1'>
                  <h1>Work Assign</h1>
                  <p>Notifies the employee about the assignment</p>
                </div>
              </Col>
            </div>
          </Col>

          <Col data-aos='fade-left' md='6'>
            <div className='card1main2 d-flex'>
              <Col md='3'>
                <div className='logocard2'>
                  <img src="./icons/icon2.png" alt="" />
                </div>
              </Col>
              <Col md='9'>
                <div className='empcardhead2'>
                  <h1>Employee Tracking</h1>
                  <p>Records and tracks the following communication between the employee and the manager.</p>
                </div>
              </Col>
            </div>
          </Col>

          <Col data-aos='fade-right' md='6'>
            <div className='card1main3 d-flex'>
              <Col md='3'>
                <div className='logocard3'>
                  <img src="./icons/icon3.png" alt="" />
                </div>
              </Col>
              <Col md='9'>
                <div className='empcardhead3'>
                  <h1>Employee Monitoring</h1>
                  <p>Helps managers define and customize job workflows.</p>
                </div>
              </Col>
            </div>
          </Col>

          <Col data-aos='fade-left' md='6'>
            <div className='card1main4 d-flex'>
              <Col md='3'>
                <div className='logocard4'>
                  <img src="./icons/icon4.png" alt="" />
                </div>
              </Col>
              <Col md='9'>
                <div className='empcardhead4'>
                  <h1>Job Distribution</h1>
                  <p>Helps to distribute jobs based on the skills and availability of workers.</p>
                </div>
              </Col>
            </div>
          </Col>





        </Row>
      </Container>
    </div>
  )
}

export default Bestemployeesection