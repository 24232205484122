import React from 'react';
import './style.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Button } from 'react-bootstrap';




const Mainbanner = () => {
  return (

    <div id='introduction' className='background'>
      <Container>
        <Row className='main-content'>
          <Col className='order-2 order-lg-1' md='6'>
            <div className='body-main-title'>
              <h1><span className='title-one'>Aspirom <br></br> Work Force</span><br></br>
              <span className='title-two'>Management System</span> </h1>
            </div>
            <div className='body-main-para'>
              <p>Technology has made work-from-home not only viable, but highly practical as well. Aspirom Technologies has explored the potential of software technology to come up with a solution for making management of remote workers easier for employers and managers. The Workforce Management System (WFMS), the unique software developed by Aspirom for employee management, gives end-to-end visibility of work and workers, in real-time. The software creates a better workforce monitoring experience for the authorities by ensuring that remote workers are connected real time and are working efficiently.</p>
            </div>
            <div className='main-button'>
              <div className='btn-getfreedemo'>
              <Button className='btn-get-btn1'>Get Free Demo</Button>
              </div>
              <div className='btn-contact'>
              <Button id='#contact' className='btn-get-btn2'>Contact Us</Button>
              </div>
            </div>
          </Col>
          <Col className='mainbanner-img order-1 order-lg-2'>
          <img src="./lap-main.png" alt="" srcset="" />
          
          </Col>
        </Row>
      </Container>


    </div>
  )
}

export default Mainbanner