import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col } from 'react-bootstrap';
import Aos from 'aos';
import "aos/dist/aos.css";
import { useEffect } from 'react';
import './style.css';



const Letsstarted = () => {
    useEffect(() => {
        Aos.init({});
      }, []);
    return (
        <div className='letsstarted'>
            <Container>
                <Row>
                    <Col data-aos='fade-right' md='6'>
                        <div className='imageforlrtsget'>
                            <img src="./lap-main.png" alt="" />
                        </div>
                    </Col>
                    <Col md='6'>
                        <div className='letsstarted-seccard'>
                            <div data-aos='fade-left' className='quoteimg'>
                                <img src="./icons/quoteup.png" alt="" />
                            </div>
                            <div className='titlesection'>
                                <h1 data-aos='fade-left'>Let’s Get <br></br> Started</h1>
                                <div data-aos='fade-left' className='quoteimg2'>
                                <img src="./icons/quotedown.png" alt="" />
                            </div>
                                <p data-aos='fade-left'>In short, Workforce Management System not only acts as a job scheduling platform, but as a communication channel and enables real-time connection between employees and their enterprises</p>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Letsstarted