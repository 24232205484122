
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col } from 'react-bootstrap';
import './style.css';
import Aos from 'aos';
import "aos/dist/aos.css";
import { useEffect } from 'react';

const Features = () => {
    useEffect(() => {
        Aos.init({});
    }, []);
    return (
        <div id='features' className='features'>
            <Container>
                <Row>
                    <Col md='12'>
                        <div className='mainheadfeature'>
                            <h1>Features.</h1>
                        </div>
                    </Col>
                    <Col md='6'>
                        <div className='mainfeaturecard'>
                            <Row>
                                <Col data-aos='fade-right' md='3'>
                                    <div className='iconfeature bg-red'>
                                        <img src="./icons2/1.png" alt="" />
                                    </div>
                                </Col>
                                <Col data-aos='fade-right' md='9'>
                                    <div className='featuretitle'>
                                        <h1>Location Based <br></br> Employee Tracking</h1>
                                        <p>As the software is primarily for remote employee management, it has the option for location-based employee tracking. It makes remote employee monitoring more practical and efficient.</p>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                    <Col md='6'>
                        <div className='mainfeaturecard'>
                            <Row>
                                <Col data-aos='fade-left' md='3'>
                                    <div className='iconfeature bg-blue'>
                                        <img src="./icons2/2.png" alt="" />
                                    </div>
                                </Col>
                                <Col data-aos='fade-left' md='9'>
                                    <div className='featuretitle'>
                                        <h1>Advanced Filtering <br></br>And Sorting Options</h1>
                                        <p>This feature makes navigating across data sections easier and allows you to find the information that you are looking for in no time.</p>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                    <Col md='6'>
                        <div className='mainfeaturecard'>
                            <Row>
                                <Col data-aos='fade-right' md='3'>
                                    <div className='iconfeature bg-orange'>
                                        <img src="./icons2/3.png" alt="" />
                                    </div>
                                </Col>
                                <Col data-aos='fade-right' md='9'>
                                    <div className='featuretitle'>
                                        <h1>Intuitive User <br></br> Interface</h1>
                                        <p>It is quite easy to learn how to use this software and it could be implemented so fast, without having to spend long hours in training.</p>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                    <Col md='6'>
                        <div className='mainfeaturecard'>
                            <Row>
                                <Col data-aos='fade-left' md='3'>
                                    <div className='iconfeature bg-red'>
                                        <img src="./icons2/4.png" alt="" />
                                    </div>
                                </Col>
                                <Col data-aos='fade-left' md='9'>
                                    <div className='featuretitle'>
                                        <h1>Well Connected  <br></br> Workforce</h1>
                                        <p>Stay connected with your remote workers in real time, with the highly advanced WFMS software. Ensure that your workers are more involved, consistently productive and committed to goals.</p>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                    <Col md='6'>
                        <div className='mainfeaturecard'>
                            <Row>
                                <Col data-aos='fade-right' md='3'>
                                    <div className='iconfeature bg-blue'>
                                        <img src="./icons2/5.png" alt="" />
                                    </div>
                                </Col>
                                <Col data-aos='fade-right' md='9'>
                                    <div className='featuretitle'>
                                        <h1>Instant Job  <br></br>Status Updates</h1>
                                        <p>WFMS helps to be updated about the progress of a work being done by a remote worker. It enables you to ensure that the work is keeping ideal pace and would be completed on time.</p>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                    <Col md='6'>
                        <div className='mainfeaturecard'>
                            <Row>
                                <Col data-aos='fade-left' md='3'>
                                    <div className='iconfeature bg-orange'>
                                        <img src="./icons2/6.png" alt="" />
                                    </div>
                                </Col>
                                <Col data-aos='fade-left' md='9'>
                                    <div className='featuretitle'>
                                        <h1>Customized User<br></br>Permissions</h1>
                                        <p>You can decide who has access to what information. This feature gives you control and makes it easier to distribute responsibilities.</p>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                    <Col md='6'>
                        <div className='mainfeaturecard'>
                            <Row>
                                <Col data-aos='fade-right' md='3'>
                                    <div className='iconfeature bg-red'>
                                        <img src="./icons2/7.png" alt="" />
                                    </div>
                                </Col>
                                <Col data-aos='fade-right' md='9'>
                                    <div className='featuretitle'>
                                        <h1>End To End <br></br> Security</h1>
                                        <p>WFMS software guarantees data safety with its advanced security features. With this software, your employee data is well protected.</p>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                    <Col md='6'>
                        <div className='mainfeaturecard'>
                            <Row>
                                <Col data-aos='fade-left' md='3'>
                                    <div className='iconfeature bg-blue'>
                                        <img src="./icons2/8.png" alt="" />
                                    </div>
                                </Col>
                                <Col data-aos='fade-left' md='9'>
                                    <div className='featuretitle'>
                                        <h1>Easy Job  <br></br>Scheduling</h1>
                                        <p>WFMS has some unique features that make the scheduling and assigning of jobs easier for managers. It brings more clarity on the tasks and timeline set for each work.</p>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                    <Col md='6'>
                        <div className='mainfeaturecard'>
                            <Row>
                                <Col data-aos='fade-right' md='3'>
                                    <div className='iconfeature bg-orange'>
                                        <img src="./icons2/9.png" alt="" />
                                    </div>
                                </Col>
                                <Col data-aos='fade-right' md='9'>
                                    <div className='featuretitle'>
                                        <h1>Real-time visibility <br></br>of workflow</h1>
                                        <p>WFMS enables managers to monitor workflow in real time, which enables timely delivery of projects and instant action if there is lag.</p>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>

            </Container>
            <div className='footerbanner'>
                <img src="./footer banner.png" alt="" />
            </div>
        </div>
    )
}

export default Features