import React from 'react';
import './header.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Nav, Navbar, Button} from 'react-bootstrap';
import { FaFacebook, FaInstagram, FaTwitter, FaLinkedinIn } from "react-icons/fa";
import { FiAlignRight } from "react-icons/fi";



const Header = () => {
  return (
    <Navbar sticky="top" className='headermain' expand="lg">
      <Container>
        <Navbar.Brand className='logomain' href="#home">
        <img src="./logo_main.png" alt=""/>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" className='harmburger'><FiAlignRight/></Navbar.Toggle>
        
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className='navbarmenu'>
            <Nav.Link className='active' href="#introduction">Introduction</Nav.Link>
            <Nav.Link href="#bestemployee">Benifits</Nav.Link>
            <Nav.Link href="#howitswork">How WFMS Work</Nav.Link>
            <Nav.Link href="#features">Features</Nav.Link>
            <Nav.Link href="#contact">Contact</Nav.Link>
            
          </Nav>
          <div className='social-icons'>
                <div className='facebook'><FaFacebook/></div>
                <div className='facebook'><FaInstagram/></div>
                <div className='facebook'><FaTwitter/></div>
                <div className='facebook'><FaLinkedinIn/></div>
            </div>
            
            <div className='btn-get'>
            <Button className='btn-get-btn'>Get Free Demo</Button>
            </div>
            
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}

export default Header