
import './App.css';
import Bestemployeesection from './components/body/bestemployeemgt';
import Features from './components/body/features';
import Footer from './components/body/footer';
import Howitswork from './components/body/howitswork';
import Letsstarted from './components/body/letsstarted';
import Mainbanner from './components/body/mainbanner';
import Header from './components/header/header';

function App() {
  return (
    <div className="App">
     
      <Header/>
      <Mainbanner/>
      <Bestemployeesection/>
      <Letsstarted/>
      <Howitswork/>
      <Features/>
      <Footer/>
     
    </div>
  );
}

export default App;
