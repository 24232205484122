import React from 'react'
import { Container, Row, Col, Form, FloatingLabel, Button } from 'react-bootstrap';

const Footer = () => {
  return (
    <div id='contact' className='footer'>
      <Container>
                <Row>
                    <Col className='contactudtitle' md='12'>
                        <h1>Questions? <br></br>Let's Get In Touch</h1>
                    </Col>
                </Row>
                <Row>
                    <Col md='6'>
                        <div className='form'>
                            <Form>
                                <Form.Group controlId="formBasicEmail">
                                    <Form.Control className='inputcontact' type="name" placeholder="Name" />
                                </Form.Group>
                                <Form.Group controlId="formBasicEmail">
                                    <Form.Control className='contactnumber' type="number" placeholder="Contact Number" />
                                </Form.Group>
                                <Form.Group controlId="formBasicEmail">
                                    <Form.Control className='inputcontact' type="email" placeholder="Enter email" />
                                </Form.Group>
                                <FloatingLabel>
                                    <Form.Control as="textarea" placeholder="Leave a comment here" style={{ height: '100px' }} />
                                </FloatingLabel>
                                <Button className='contact-freedemo-btn'>SUBMIT AND GET FREE DEMO</Button>
                            </Form>
                        </div>
                    </Col>
                    <Col md='6'>
                        <div className='contact-address'>
                            <div className='titile-contact'><h1>Office Location</h1>
                                <p>Are you interested to explore more about
                                    AspiromCRM? Connect with us right away!</p>
                            </div>

                            <div className='titile-contact1'>
                                <Row className='adressbar'>
                                    <Col className='iconc' md='1'>
                                        <img src="./home_icon.png" alt="" srcset="" />
                                    </Col>
                                    <Col className='adresstext' md='11'>
                                        Aspirom Technologies,<br></br>
                                        3rd floor, KC Center,<br></br>
                                        Karunagappally, Kollam, Kerala<br></br>
                                        India - 690 518
                                    </Col>
                                </Row >
                                <Row className='adressbar'>
                                    <Col className='iconc' md='1'>
                                        <img src="./call.png" alt="" srcset="" />
                                    </Col>
                                    <Col className='adresstext' md='11'>
                                        +91 999 553 9759
                                    </Col>
                                </Row>
                                <Row className='adressbar'>
                                    <Col className='iconc' md='1'>
                                        <img src="./email.png" alt="" srcset="" />
                                    </Col>
                                    <Col className='adresstext' md='11'>
                                        susanth malanada@gmail.com
                                    </Col>
                                </Row>
                            </div>


                        </div>
                    </Col>
                </Row>
            </Container>
    </div>
  )
}

export default Footer